import React from 'react';
import './servicedetails.css';
import service1 from "./images/service01.jpg";
import service2 from "./images/digital.svg";
import service3 from "./images/chart.svg";
import service4 from "./images/marketing.svg";
import service5 from "./images/cybersecurity.svg";

function ServiceDetails() {
  return (
    <>
      <section className="service-details bg-light pt-5 mb-10 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="body-heading mb-4 mt-4">Our Services</h1>
            </div>
            <div className="col-md-6">
              <img src={service1} alt="service1"></img>
            </div>
            <div className="col-md-6">
              <h2 className="h3 mb-sm-4">Infrastructure Services</h2>
              <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
                Quality infrastructure support for systems, devices, servers,
                firewalls, networks, desktops, and backup devices.
              </p>
              <div className="d-flex border p-2">
                <div className="service-details-box d-flex align-items-center justify-content-center border-end">
                  <div className="s-icon">
                    <img src={service2} alt="Icon1"></img>
                  </div>
                  <div className="s-text ps-2">
                    <h6 className=" mb-0">Proactive Management</h6>
                    <p className="fs-xs mb-0 fx-1">
                      Proactive monitoring and maintenance allows you to fix
                      problems before they arise.
                    </p>
                  </div>
                </div>

                <div className="service-details-box d-flex align-items-center justify-content-center">
                  <div className="s-icon">
                    <img src={service3} alt="Icon1"></img>
                  </div>
                  <div className="s-text ps-2">
                    <h6 className=" mb-0">Speedy Issue Resolution</h6>
                    <p className="fs-xs mb-0 fx-1">
                      Service Desk support lets you resolve technical issues
                      quickly and efficiently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex border p-2 mt-2">
                <div className="service-details-box d-flex align-items-center justify-content-center border-end">
                  <div className="s-icon flex-grow-1">
                    <img src={service4} alt="Icon1"></img>
                  </div>
                  <div className="s-text ps-2 ">
                    <h6 className=" mb-0">Increase Uptime</h6>
                    <p className="fs-xs mb-0">Maximize your system uptime.</p>
                  </div>
                </div>

                <div className="service-details-box d-flex align-items-center justify-content-center">
                  <div className="s-icon">
                    <img src={service5} alt="Icon1"></img>
                  </div>
                  <div className="s-text ps-2">
                    <h6 className=" mb-0">Modern Processes and Tools</h6>
                    <p className="fs-xs mb-0">
                      Proactive monitoring and maintenance allows you to fix
                      problems before they arise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceDetails