import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="footer dark-mode bg-dark pt-5 pb-4 pb-lg-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <img
                className="top-logo"
                src={process.env.PUBLIC_URL + "images/logo-dark (1).png"}
                alt="Logo"
              />
              <p className="text-light mt-4">
                Being a contemporary organization in the pool of saturated IT
                industry, we aspire to “Listen” to the problems to come up with
                a “solution”.
              </p>
            </div>
            <div className="col-md-4 ps-5">
              <h6 className="mb-2">
                <Link
                  to="/"
                  className="d-block text-dark dropdown-toggle  py-2 text-white text-decoration-none"
                  data-bs-toggle="collapse"
                >
                  Useful Links
                </Link>
              </h6>
              <ul className="nav flex-column pb-lg-1 mb-lg-3 footer-link">
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/about"}
                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/services"}
                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact"}
                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h6 className="mb-2">
                <Link
                  to="/"
                  className="d-block text-dark dropdown-toggle  py-2 text-white text-decoration-none"
                  data-bs-toggle="collapse"
                >
                  Follow Us
                </Link>
              </h6>
              <div className="d-flex mb-4 mb-sm-0 ">
                <a
                  href="https://www.linkedin.com/company/sigmen-technologies-solutions"
                  className="btn btn-icon btn-secondary btn-linkedin rounded-circle me-2 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.facebook.com/sigmentech"
                  className="btn btn-icon btn-secondary btn-facebook rounded-circle me-2 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a
                  href="https://twitter.com/sigmentech"
                  className="btn btn-icon btn-secondary btn-twitter rounded-circle me-2 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/sigmen_technologies_solutions/"
                  className="btn btn-icon btn-secondary btn-youtube rounded-circle me-2 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer