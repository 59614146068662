import React from 'react'
import './whychoose.css'

function WhyChoose() {
  return (
    <>
      <section className="whychoose mb-10 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2 className="h1 text-center pt-1 pt-xl-2 mb-4 mt-10">
                Why Choose Us?
              </h2>
              <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">
                We personalize how we work to fit your project needs. Our
                approach helps augment innovation.
              </p>
            </div>
            <div className="col-md-12">
              <div className="choose-box-content">
                <ul>
                  <li>
                    <i class="fa-solid fa-building-shield mb-4 fa-2x"></i>
                    <h4>Proactive Management</h4>
                  </li>
                  <li>
                    <i class="fa-solid fa-users mb-4 fa-2x"></i>
                    <h1>50+</h1>
                    <p>Number of Employees</p>
                  </li>
                  <li class="d-flex justify-content-center align-items-center p-0">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="choose-box">
                        <i class="fa-solid fa-code-branch fa-4x me-5"></i>
                      </div>
                      <div className="choose-box">
                        <h4>Modern Processes & Tools</h4>
                      </div>
                    </div>
                  </li>
                  <li>
                    <i class="fa-solid fa-clock-rotate-left mb-4 fa-2x"></i>
                    <h4>Increase Uptime</h4>
                  </li>
                  <li>
                    <i class="fa-solid fa-building-circle-arrow-right fa-2x mb-4"></i>
                    <h1>2021</h1>
                    <p>Established</p>
                  </li>
                  <li>
                    <i class="fa-solid fa-users-gear fa-2x mb-4"></i>
                    <h4>Top Developers</h4>
                  </li>
                  <li>
                    <i class="fa-solid fa-star mb-4 fa-2x"></i>
                    <h1>20 +</h1>
                    <p>Five Start Reviews</p>
                  </li>
                  <li>
                    <i class="fa-solid fa-laptop-code fa-2x mb-4"></i>
                    <h4>Latest Technology</h4>
                  </li>
                  <li>
                    <i class="fa-solid fa-award fa-2x mb-4"></i>
                    <h4>Quality Service</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChoose