import React from 'react'
import './about.css'

const About = () => {
  return (
    <>
      <section className="about bg-light pt-5 mb-10 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="body-heading mb-4 mt-4">About Sigmen</h1>
              <p className="color-light mb-4 pe-5">
                Coming from one of the top 3 IT companies across the globe with
                amassed experience of 75+ years in the IT industry, our board Of
                directors brings the perfect blend of knowledge & experience
                which stands still with us & for the organization.
              </p>
              <p className="color-light mb-4 pe-5">
                Being a contemporary organization in the pool of saturated IT
                industry, we strive to bring change in the basic approach of
                being business solution providers by terminating the ancient
                method of “hearing” to the problem and providing a “software”,
                in fact, we aspire to “Listen” to the problems to come up with a
                “solution”..
              </p>
              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="mvg_box text-center mvg_box1">
                    <i class="fa-solid fa-lightbulb fa-2x"></i>
                    <h4 class="mb-2 pb-1 pt-4">MISSION</h4>
                    <p className="mx-auto">
                      Our mission is to provide innovative, diversified, secure,
                      cost-effective and high quality products, solutions and
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mvg_box text-center mvg_box2">
                    <i class="fa-solid fa-award fa-2x"></i>
                    <h4 class="mb-2 pb-1 pt-4">MISSION</h4>
                    <p className="mx-auto">
                      Our mission is to provide innovative, diversified, secure,
                      cost-effective and high quality products, solutions and
                      services.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mvg_box text-center mvg_box1 mt-4">
      
                    <i class="fa-solid fa-people-group fa-2x"></i>
                    <h4 class="mb-2 pb-1 pt-4">VALUE</h4>
                    <p className="mx-auto">
                      Our company is built on trust, respect and care for our
                      people, our communities and our customers. We are
                      committed to serve our clients with honesty and topmost
                      integrity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <img
                    className="about_img mt-5"
                    src={process.env.PUBLIC_URL + "images/01.jpg"}
                    alt="Logo"
                  />
                </div>
                <div className="col-md-6">
                  <img
                    className="about_img"
                    src={process.env.PUBLIC_URL + "images/02.jpg"}
                    alt="Logo"
                  />
                </div>
                <div className="col-md-6">
                  <img
                    className="about_img mt-5"
                    src={process.env.PUBLIC_URL + "images/03.jpg"}
                    alt="Logo"
                  />
                </div>
                <div className="col-md-6">
                  <img
                    className="about_img"
                    src={process.env.PUBLIC_URL + "images/04.jpg"}
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About