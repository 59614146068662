import React from 'react';
import './errorpage.css';
import errorpic from "./SearchingLooking.svg";
import {Link} from 'react-router-dom'



function ErrorPage() {
  return (
    <>
      <section className="error bg-light">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                <img src={errorpic} alt="404"></img>
                <h4 className="mt-5">Page Not Found !</h4>
                <Link to="/" className="btn-sm btn-primary mb-10 text-decoration-none text-white mt-2">
                  Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ErrorPage