import React from 'react';
import "./testimonial.css";
import { Navigation, Pagination,Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";



function Testimonial() {
  return (
    <>
      <section className="testimonials bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="h1 text-center pt-1 pt-xl-2 mt-10">
                Testimonials
              </h2>
            </div>
            <div className="col-md-12">
              <Swiper
                modules={[Navigation, Pagination, Autoplay, A11y, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                // navigation
                pagination={{ clickable: true }}
                autoplay={{ disableOnInteraction: false, delay: 6000 }}
                effect="fade"
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide className="slide_box">
                  <p className="fs-sm color-light mb-4">
                    Great work with fine professionalism. They were able to take
                    abstract ideas and turn them into a professional image that
                    could be an important presentation. I plan to use their help
                    again in future in some other form or other. This team is
                    reliable to the core and they can go beyond certain limits
                    to help the customer to fulfill their requirement. Thanks
                    guys and I really appreciate your help and support. Wish you
                    all the best for your future projects.
                  </p>
                  <div className="client-bx">
                    <h6> Custom modification of existing application</h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide_box">
                  <p className="fs-sm color-light mb-4">
                    Being a prestigious client of this hardworking team, I am
                    expressing my heartfelt gratitude towards the core team. I
                    got the opportunity to work with the core team very closely
                    and they have supported each of our requirements very
                    seriously. The team is very dedicated, hardworking and
                    focused on client’s requirements.
                  </p>
                  <div className="client-bx">
                    <h6> Prominent Event Planners from Kolkata</h6>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial