import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

const Head = () => {
  return (
    <>
      <Navbar expand="lg" className="px-3">
        <>
          <Navbar.Brand>
            <Link to="/">
              <img
                className="top-logo"
                src={process.env.PUBLIC_URL + "images/logo-dark (1).png"}
                alt="Logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto main-menu d-flex align-items-center justify-content-center">
              <ul className="main-nav">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/servicesdetails">Services</Link>
                </li>
                <li>
                  <Link to="/Contact">Contact Us</Link>
                </li>
              </ul>

              <Nav.Link href="#home" className="gradient">
                <span className="top-btn btn-sm">
                  Call us now - +1 (647) 574-4990 / +1 (416) 879-2665
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    </>
  );
};

export default Head;
