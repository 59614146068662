import React from "react";
import "./service.css";
import { Link } from "react-router-dom";

function Service() {
  return (
    <>
      <section className="bg-light">
        <div className="container-fluid">
          <div className="row">
            <h1 className="body-heading mb-2 mt-10">Our Services</h1>
          </div>
          <div className="row align-items-center pb-5 mb-lg-2">
            <div className="col-md-8 text-center text-md-start">
              <p className="fs-lg text-muted mb-md-0">
                No matter what your IT needs are, we offer the services and
                solutions to meet them quickly and efficiently
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-center justify-content-md-end">
              <Link to="/" className="btn btn-outline-primary btn-lg">
                See all services
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-server d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Server Management
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Server Configuration, Server Hardening, Server
                      Administration Maintenance.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-desktop d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      System/Desktop Management
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Systems Support, Systems Configuration, Patching,
                      Updating, Managing personal computers, Backup, Storage and
                      Supporting end users.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-shield-halved d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Security Management
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Password Management, Anti-Virus Installation, Security
                      Firewalls, Security Patches Administration, External
                      Connections and Remote Access Monitoring, Security
                      Incident Reporting, General Security and User
                      Requirements.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-wifi d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Networks Management
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Network Design, Network Implementation, Network
                      Administration & Maintenance, Troubleshooting.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-fire d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Firewalls Management
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Firewall Setup and Maintenance.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-brands fa-ubuntu d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Operating Systems
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      OS Patches Installation, Error Detection, Troubleshooting,
                      Maintenance, Monitoring.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-solid fa-code d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Custom Software Development
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Nisi, dis sed cursus eget pellentesque mattis. Odio eu
                      proin aliquam a. Semper bibendum tellus non tellus,
                      facilisi dignissim in quam massa. Aliquam, feugiat ut cum
                      tellus, sit. Quis consectetur gravida ac ac lectus cursus
                      egestas.
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col py-4 my-2 my-sm-3">
                <Link
                  to="/"
                  class="card card-hover h-300 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
                >
                  <div className="card-body pt-3">
                    <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                      <i className="fa-brands fa-app-store-ios d-block m-1 text-white fa-2x"></i>
                    </div>
                    <h2 className="h4 d-inline-flex align-items-center text-sigmen">
                      Mobile App Development
                      <i className="fa-solid fa-arrow-right text-sigmen-primary fs-3 ms-2"></i>
                    </h2>
                    <p className="fs-sm color-light mb-0">
                      Nunc, justo, diam orci, dictum purus convallis risus.
                      Suscipit hendrerit at egestas id id blandit interdum est.
                      Integer fames placerat turpis pretium quis hac leo lacus.
                      Orci, dictum nunc mus quis semper eu bibendum enim, morbi.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Service;
