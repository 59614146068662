import React from 'react'
import './contact.css'
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <section className="contact mb-5 bg-light pt-5 pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="body-heading mb-4 mt-4">Contact Us</h1>
              <div class="card card-hover mb-3 ">
                <div className="card-body d-flex align-items-start">
                  <i className="fa fa-envelope fs-3 bg-secondary text-sigmen-primary d-inline-block rounded-circle p-3"></i>
                  <div className="ps-4">
                    <h3 className="h4 pb-1 mb-2">Email us</h3>
                    <p className="pb-0 mb-0">
                      Please feel free to drop us a line. We will respond as
                      soon as possible.
                    </p>
                    <Link
                      to="/"
                      className="btn btn-link stretched-link fs-base px-0 text-decoration-none text-sigmen-primary"
                    >
                      info@sigmentechnologies.com
                      <i className="bx bx-right-arrow-alt fs-4 ms-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="card card-hover mb-3 ">
                <div className="card-body d-flex align-items-start">
                  <i className="fa-solid fa-phone fs-3 bg-secondary text-sigmen-primary d-inline-block rounded-circle p-3"></i>
                  <div className="ps-4">
                    <h3 className="h4 pb-1 mb-2">Call us</h3>
                    <p className="pb-0 mb-0">
                      If you need immediate assistance feel free to call us.
                    </p>
                    <Link
                      to="/"
                      className="btn btn-link stretched-link fs-base px-0 text-decoration-none text-sigmen-primary"
                    >
                      +1 (647) 574-4990 / +1 (416) 879-2665
                      <i className="bx bx-right-arrow-alt fs-4 ms-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="card card-hover mb-3 ">
                <div className="card-body d-flex align-items-start">
                  <i className="fa-solid fa-location-dot fs-3 bg-secondary text-sigmen-primary d-inline-block rounded-circle p-3"></i>
                  <div className="ps-4">
                    <h3 className="h4 pb-1 mb-2">Visit us</h3>
                    <p className="pb-0 mb-0">
                      25 Watline Avenue Mississauga , Ontario- L5W1R3
                    </p>
                    <Link
                      to="/"
                      className="btn btn-link stretched-link fs-base px-0 text-decoration-none text-sigmen-primary"
                    >
                      Get directions
                      <i className="bx bx-right-arrow-alt fs-4 ms-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <iframe
                className="c-map mt-5"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.2723002726416!2d-79.67416638507994!3d43.62169116248663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b40a278c00001%3A0x69d17bcd5e306500!2s25%20Watline%20Ave%2C%20Mississauga%2C%20ON%20L4Z%202Z1%2C%20Canada!5e0!3m2!1sen!2sin!4v1659703309752!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact