import React from 'react'
import { Link } from 'react-router-dom'
import  './home_body.css'

function HomeBody() {
  return (
    <>
      <div className="container-fluid mt-10">
        <div className="row">
          <div className="col-md-6">
            <h1 className='body-heading mb-4 mt-4'>We’ll relieve your technology burdens and align your IT for business success</h1>
            <p className='color-light mb-4'>We create diverse, complex, web and mobile solutions for any business need. With us you get quality software and perfect service every time.</p>
            <Link to="/about" className='btn-lg btn-primary text-white text-decoration-none'>More About Us</Link>
          </div>
          <div className="col-md-6">
            <div className="video-section mb-10">
              <video  autoPlay={"autoplay"} loop muted>
              <source src={process.env.PUBLIC_URL + "videos/home.mp4"} type="video/mp4"/>
           </video> 
       </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeBody