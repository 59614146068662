import React from 'react';
import './App.css';
import Header from './components/common/heading/Header';
import Footer from './components/common/footer/Footer'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/home/Home';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import ErrorPage from './components/common/404/ErrorPage';
import ServiceDetails from './components/serviceDetails/ServiceDetails';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/servicesdetails" exact component={ServiceDetails} />
          <Route path="/*" exact component={ErrorPage} />
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

export default App