import React from 'react'
//import { Link } from 'react-router-dom'
import './hero.css'

function Hero() {
  return (
    <>
      <section className='hero-baner'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="hero-text">
                <h1>The Best IT Solution For Your Business</h1>
                <p>We provide the wide range of high quality IT services and best practices solutions to our customers making their business better.</p>
                {/* <Link to="/about" className='btn-sm hero-btn'> Contact Us</Link> */}
              </div>
            
            </div>
          </div>
        </div>
     
      </section>
    </>
  )
}

export default Hero