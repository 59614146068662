import React from 'react'
import Head from './Head'
import './header.css'

const Header = () => {
  return (
    <>
      <Head/>
    </>
  )
}

export default Header